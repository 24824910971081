import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './button'
import { fluidRange } from 'polished'
import { animated, useSpring, config } from 'react-spring'
import useIsInViewport from 'use-is-in-viewport'
import useTrigger from '../hooks/useTrigger'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'

const StyledCta = styled(animated.div)`
  position: relative;
  color: ${({ theme }) => theme.color.grey.light};
  margin-right: 30px;

  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 2px solid ${({ theme }) => theme.color.gold};
    left: 30px;
    right: -30px;
    top: 30px;
    bottom: -30px;
  }

  @media(max-width: 700px) {
    margin-right: 20px;
    margin-left: 20px;

    &:after {
      display: none;
    }
  }
`

const Notch = styled(BackgroundImage)`
  background-color: ${({ theme }) => theme.color.purple};
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  clip-path:
    polygon(
      0 0, 0 0,
      calc(100% - 140px) 0%, 100% calc(0% + 110px),
      100% 100%, 100% 100%,
      0 100%, 0 100%
    );
  ${fluidRange({
      prop: 'padding-top',
      fromSize: '30px',
      toSize: '60px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-right',
      fromSize: '20px',
      toSize: '140px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-bottom',
      fromSize: '30px',
      toSize: '60px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-left',
      fromSize: '20px',
      toSize: '140px'
    },
    '300px',
    '1600px'
  )}

  @media(max-width: 700px) {
    display: block;
    clip-path: none;

    & > a {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
`

const Text = styled.p`
  margin: 0 12% 0 0;
  ${fluidRange({
      prop: 'font-size',
      fromSize: '18px',
      toSize: '25px'
    },
    '300px',
    '1600px'
  )}

  @media(max-width: 700px) {
    margin: 0;
  }
`

const Cta = ({ text, children, buttonText, to }) => {
  const { valoremV } = useStaticQuery(graphql`
    query {
      valoremV: file(relativePath: { eq: "valorem-v-landscape.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const containerRef = useRef(null)
  const [isInViewport, wrappedContainerRef] = useIsInViewport({
    target: containerRef,
    threshold: 40
  })
  const trigger = useTrigger(isInViewport)

  const fadeIn = useSpring({
    config: config.molasses,
    opacity: trigger ? '1' : '0',
    transform: trigger ? 'translateY(0)' : 'translateY(50px)'
  })

  return (
    <div ref={wrappedContainerRef}>
      <StyledCta style={fadeIn}>
        <Notch fluid={valoremV.childImageSharp.fluid}>
          <Text>{text}</Text>
          {to &&
            <Button
              to={to}
              css='flex-shrink: 0; display: inline-block;'
              colour='primary'
            >
              {buttonText}
            </Button>
          }
        </Notch>
      </StyledCta>
    </div>
  )
}

Cta.defaultProps = {
  buttonText: 'More info'
}

Cta.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node
}

export default Cta
